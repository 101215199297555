import axios from "../utils/axios";

class AuthService {
  setAxiosInterceptors = ({onLogout}) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication () {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }
    this.setSession(accessToken);
  }

  loginWithPersonalNumber = async (personalNumber) => {
    try {
      const {data} = await axios.post("/session-create", {
        type: "user",
        personal_number: personalNumber
      });


      const verify = await axios.get(data.confirm);

      if (verify.data) {
        this.setSession(data.token);
        const user = await axios.get("/me");
        return user.data;
      }
    } catch (err) {
      return err;
    }
  }

  loginInWithToken = async () => {
    try {
      const {data} = await axios.get("/my-account");
      return data;
    } catch (err) {
      this.setSession(null);
      return err;
    }
  }

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem("accessToken");
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem("accessToken");

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
