/* eslint-disable no-param-reassign */
import produce from "immer";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  UPDATE_USER,
  GET_CUSTOMER_INFO,
  SILENT_LOGIN, GET_CUSTOMER_NOTIFICATIONS, GET_CUSTOMER_SERVICES, GET_CUSTOMER_SUBSCRIPTION, GET_CUSTOMER_ACCOUNT
} from "src/actions/accountActions";

const initialState = {
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
  case LOGIN_REQUEST: {
    return produce(state, (draft) => {
      // Ensure we clear current session
      draft.user = null;
    });
  }

  case LOGIN_SUCCESS: {
    const {user} = action.payload;

    return produce(state, (draft) => {
      draft.user = user;
    });
  }

  case LOGIN_FAILURE: {
    return produce(state, () => {
      // Maybe store error
    });
  }

  case UPDATE_USER: {
    const {user} = action.payload;
    return produce(state, (draft) => {
      draft.user = user;
    });
  }

  case LOGOUT: {
    return produce(state, (draft) => {
      draft.user = null;
    });
  }

  case SILENT_LOGIN: {
    const {user} = action.payload;

    return produce(state, (draft) => {
      draft.user = user;
    });
  }

  case GET_CUSTOMER_ACCOUNT: {
    const user = action.payload;

    return produce(state, (draft) => {
      draft.user = user;
    });
  }

  case GET_CUSTOMER_INFO: {
    const info = action.payload;

    return produce(state, (draft) => {
      draft.info = info;
    });
  }

  case GET_CUSTOMER_NOTIFICATIONS: {
    const notifications = action.payload;

    return produce(state, (draft) => {
      draft.notifications = notifications;
    });
  }

  case GET_CUSTOMER_SERVICES: {
    const services = action.payload;

    return produce(state, (draft) => {
      draft.services = services;
    });
  }

  case GET_CUSTOMER_SUBSCRIPTION: {
    const subscription = action.payload;

    return produce(state, (draft) => {
      draft.subscription = subscription;
    });
  }

  default: {
    return state;
  }
  }
};

export default accountReducer;
