import authService from "src/services/authService";
import axios from "../utils/axios";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const UPDATE_USER = "@account/update-user";
export const GET_CUSTOMER_ACCOUNT = "@account/get-account";
export const GET_CUSTOMER_INFO = "@account/get-info";
export const GET_CUSTOMER_NOTIFICATIONS = "@account/get-notifications";
export const GET_CUSTOMER_SERVICES = "@account/get-services";
export const GET_CUSTOMER_SUBSCRIPTION = "@account/get-subscription";

export function setUserData (user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function getUserData () {
  return async (dispatch) => {
    try {
      const {data} = await axios.get("/my-account");
      dispatch({
        type: GET_CUSTOMER_ACCOUNT,
        payload: {
          ...data
        }
      });

    } catch (error) {
      console.log(error);
    }
  };
}

export function setUserInfo () {
  return async (dispatch) => {
    try {
      const {data} = await axios.get("/my-info");
      dispatch({
        type: GET_CUSTOMER_INFO,
        payload: {
          ...data
        }
      });

    } catch (error) {
      console.log(error);
    }
  };
}

export function setUserNotifications () {
  return async (dispatch) => {
    try {
      //TODO update this when implementing proper pagination
      const {data} = await axios.get(`/my-notifications?limit=100&offset=0`);
      dispatch({
        type: GET_CUSTOMER_NOTIFICATIONS,
        payload: {
          ...data
        }
      });

    } catch (error) {
      console.log(error);
    }
  };
}

export function setUserServices () {
  return async (dispatch) => {
    try {
      const {data} = await axios.get("/my-services");
      dispatch({
        type: GET_CUSTOMER_SERVICES,
        payload: {
          ...data
        }
      });

    } catch (error) {
      console.log(error);
    }
  };
}

export function setUserSubscription () {
  return async (dispatch) => {
    try {
      const {data} = await axios.get("/my-subscription");
      dispatch({
        type: GET_CUSTOMER_SUBSCRIPTION,
        payload: {
          ...data
        }
      });

    } catch (error) {
      console.log(error);
    }
  };
}

export function logout () {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}
